import React from 'react'

// Compnents
import { InfoContainer, Info } from 'components/core/info';
import { Button } from 'components/core/button';
import { Modal } from 'components/core/modal';
import { ModifiableSelectField } from 'components/tools/modifiable_fields';
import { CheckBox } from 'components/tools/checkbox';
import { SelectCronPeriod } from 'components/tools/select_cron_period';
import { SelectPairingType } from 'components/tools/select_pairing_type';
import { Error } from 'components/core/typo'

// Hooks
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useAPI } from 'hooks/useAPI';

// Utils
import { cronsIntervalToString } from 'utils/crons';

//API
import { createPairing } from 'api/pairings';
import { SelectSchoolSubjectsDropdown } from 'components/tools/select_school_subjects';

export function CreatePairingModal({open, setOpen, draft, onResult}){
  const [pairing, setPairing] = useState(({...draft, studentConfirmed: true, tutorConfirmed: true}));
  const { t } = useTranslation("autoPairing");
  const [result, {loading, error, execute}] = useAPI(createPairing, pairing, {immediate:false, onResult});

  return <Modal open={open} setOpen={setOpen}>
          <Modal.Title>
            {t("create-pairing.title")}
          </Modal.Title>
          <Modal.Body>
          {!result && pairing&&
            <>
              <div className="flex w-full space-x-2">
                <InfoContainer containerClassName="flex-1">
                  <Info.Field value={pairing.student.name} label={t("student.title")}/>
                </InfoContainer>
                <InfoContainer containerClassName="flex-1">
                  <Info.Field value={pairing.tutor.name} label={t("tutor")}/>
                </InfoContainer>
              </div>
              <InfoContainer modify={true} className="space-y-0">
                  <ModifiableSelectField 
                      Field={CheckBox}
                      label={t("student-has-confirmed-pairing")} 
                      modify={true}
                      marker="select"
                      value={pairing.studentConfirmed} 
                      setValue={(studentConfirmed)=>setPairing(d=>({...d, studentConfirmed}))}/>
                  <ModifiableSelectField 
                      Field={CheckBox}
                      label={t("tutor-has-confirmed-pairing")} 
                      modify={true}
                      marker="select"
                      value={pairing.tutorConfirmed} 
                      setValue={(tutorConfirmed)=>setPairing(d=>({...d, tutorConfirmed}))}/>
                  <ModifiableSelectField 
                    Field={SelectCronPeriod} 
                    label={t("weekly-period")} 
                    subLabel={t("shared")+pairing.matched_schedule.map(s=>`${cronsIntervalToString(s)}`).join(', ')}
                    modify={true}
                    value={pairing.period} 
                    setValue={(period)=>setPairing(d=>({...d, period}))}/>
                  <ModifiableSelectField 
                    Field={SelectPairingType} 
                    label={t("pairing-type")} 
                    subLabel={t("shared")+((pairing.student.on_site_preference &&pairing.tutor.on_site_preference)? t("preferences.onsite"):
                      (pairing.student.online_preference &&pairing.tutor.online_preference)? t("preferences.online"):
                      (pairing.student.hybrid_preference &&pairing.tutor.hybrid_preference)? t("preferences.hybrid"):
                      t("preferences.undefined")
                      )}
                    removeHybrid={false}
                    modify={true}
                    value={pairing.preferedType} 
                    setValue={(preferedType)=>setPairing(d=>({...d, preferedType}))}/>  
                      {/* Schools subjects */}
                <ModifiableSelectField 
                    Field={SelectSchoolSubjectsDropdown} 
                    label={t("pairing-subjects")} 
                    subLabel={t("shared")+pairing.matched_subjects.map(s=>`${s.name}`).join(', ')}
                    modify={true}
                    block={true}
                    noBorder={true}
                    value={pairing.subjects || []} 
                    setValue={(subjects)=>setPairing(d=>({...d, subjects}))}/>  
              </InfoContainer>
                <Button loading={loading} onClick={execute} block color="active" className="mt-5" size="lg" disabled={!pairing.preferedType || !pairing.period}>
                  {t("create-pairing.button")}
                </Button>
              {error? <Error.Text {...error}/>: null}
            </> }
          {result && 
              <div>
                <p>{t("pairing-created")}</p>
              </div>
          }
          </Modal.Body>
        </Modal>
}
