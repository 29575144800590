import React from 'react';

// Components
import { DashboardLayout } from 'components/core/layouts';
import { Outlet } from "react-router-dom";
import { FilterBar } from 'components/tutoring-follow-up/filter_bar';

// Utils
import { useMatch } from 'react-router-dom';

// Contexts
import { TutoringFollowUpContextProvider } from 'contexts/tutoring_follow_up';

// Hooks

export function TutoringFollowUpBaseWithContext(){
  const match = useMatch(`/tutoring-follow-up/:id/:tab`);
  return <DashboardLayout contentClassName={"overflow-hidden"}>
            <FilterBar presetTo={(d)=>d?`/tutoring-follow-up/${d.id}${match &&match.params && match.params.tab? `/${match.params.tab}`: "/bdt"}`: `/tutoring-follow-up`} 
                      limitToUser={true} searchPreset />
            <div className="h-full flex-1 overflow-auto">
              <Outlet/>
            </div>
           </DashboardLayout>
}

export function TutoringFollowUpBase(){
  return <TutoringFollowUpContextProvider storageKey="tutoring-follow-up">
            <TutoringFollowUpBaseWithContext/>
          </TutoringFollowUpContextProvider>
}

import { default as empty } from 'pages/tutoring-follow/select'
import {default as notes} from 'pages/tutoring-follow/notes'
import {default as bdt} from 'pages/tutoring-follow/bdt'
import {default as stats} from 'pages/tutoring-follow/stats'
export const TutoringFollowUpPages = {root: TutoringFollowUpBase, empty, notes, bdt, stats}