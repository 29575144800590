import React from 'react';

// API
import { searchGroupedStudySessions} from 'api/study_sessions';

// Hooks
import { useMemo, useState } from 'react';
import { useSearch } from 'hooks/useSearch';
import { useWeek } from 'hooks/dates/useWeek'
import { useTranslation } from 'react-i18next';
import {cronToString} from 'utils/crons';

// Utils
import classnames from 'classnames';
import { locale, dateParse } from 'utils/locale';
import { timeWeek, timeMonday, timeDay } from 'd3-time';

// Components
import { Button } from 'components/core/button';
import { StudySessionIcons, StudySessionIconContent } from 'components/study-sessions/icons';
import { Dropdown } from 'components/core/dropdown';
import { FiMoreVertical, FiMaximize2, FiWifi, FiUsers, FiExternalLink } from 'react-icons/fi';
import { Table } from 'components/core/table';
import { EmptyState } from 'components/core/empty';
import { TooltipContent } from 'components/events/play_time_view'
import { Tooltip } from 'components/core/tooltip'
import { SelectActivePairing } from 'components/tools/select_active_pairing';
import { Skeleton } from 'components/core/skeleton';
import { Fragment } from 'react';
import { CreateNoteInModal } from 'components/notes/create_modal';
import { SelectPeriods } from 'components/tools/select_period';
import { getFullCurrentPeriod } from 'utils/period';
import { ChangeWeek } from 'components/follow-up/aad';
import { Error } from 'components/core/typo';
import { StatsCardScoreSmall, StatsCardSmall } from 'components/coordo-stats/stats-card';

export function Cell({pairing, date, preset, studySessions, fails, position="center"}){
  
  const pairingHasntStarted = useMemo(()=> timeWeek.floor(new Date(date))<timeWeek.floor(dateParse(pairing.started_at)), [date, pairing]);
  const pairingIsFinished = useMemo(()=> pairing.ended_at && timeWeek.floor(new Date(date))>timeWeek.floor(dateParse(pairing.ended_at)), [date, pairing]);
  const pairingIsActive = !pairingIsFinished && !pairingHasntStarted;

  return <div className="border-r ">
          <div className={classnames("flex justify-center items-center whitespace-nowrap space-x-6 ", studySessions.length>1 && "px-2")}>
          {studySessions && studySessions.map(d=>{
            return <div key={d.id} className="">
                    <Tooltip  
                      content={<TooltipContent studySessions={[d]} fails={fails}/>} 
                      position={position}
                      color="light" 
                      delay={0} 
                      className="fixed translate-x-6">
                       <div  className='relative'><StudySessionIconContent {...d}/></div>
                    </Tooltip>
                    <div className="flex items-center space-x-1">
                      <StudySessionIcons preset={preset} {...d}/>
                    </div>
                  </div>}
          )}
          {pairingIsActive && studySessions.length===0 && <div className="px-2 py-1 my-1 text-center text-gray-600 bg-gray-100 rounded ">0</div>}
          </div>
          {(pairingIsFinished || pairingHasntStarted)  && <div className={classnames(" h-8 w-full bg-gray-200  mt-auto")}></div>}

        </div>
}

export function StudentCell({student, pairing}){
  return <div className="flex flex-col my-2 whitespace-nowrap">
          <Button target="_blank"  color="hiddenLink" className="font-medium" href={`/students/${student.ni}`}>{student.name}</Button>
          <Button target="_blank" color="hiddenLink"  href={`/tutors/${pairing.tutor.ni}`}>{pairing.tutor.name}</Button>
          </div>
}

export function SettingsCell({student, pairing}){
  const { t } = useTranslation("common");
  const [showAddNote, setShowAddNote] = useState(false)
  return  <Fragment>
            <Dropdown onlyIcon={<FiMoreVertical/>} itemClassName="whitespace-nowrap" orientation="right">
              <Dropdown.Item onClick={()=>setShowAddNote(true)} name={t('new-note')} icon={<FiMaximize2/>} color="default"/>
              <Dropdown.Item href={`/pairings/${pairing.id}`} name={t('jump-to-pairing')} icon={<FiExternalLink/>} color="default"/>
            </Dropdown>
            <CreateNoteInModal open={showAddNote} setOpen={setShowAddNote} creatorProps={{defaultStudent: student, defaultPairing: {...pairing, student}}}/>

          </Fragment>
}
export function InfoCell({pairing}){
  const period = <p className="text-sm text-gray-500">{cronToString(pairing.period)}</p>;
  return <div className="my-2 whitespace-nowrap">
                 {pairing.ended_at && <p className="text-sm text-gray-500">{locale.format("%d %B")(dateParse(pairing.started_at))} au {locale.format("%d %B")(dateParse(pairing.ended_at))}</p>}
                {!pairing.ended_at && <p className="text-sm text-gray-500">Depuis le {locale.format("%d %B")(dateParse(pairing.started_at))}</p>}
                {pairing.prefered_type && pairing.prefered_type.code==="en-personne"&& <div className="flex items-center space-x-2 text-sm text-gray-700"><FiUsers/> <p>{pairing.prefered_type.name}</p> {period}</div>}
                {pairing.prefered_type && pairing.prefered_type.code==="en-ligne"&& <div className="flex items-center space-x-2 text-sm text-green-600"><FiWifi/> <p>{pairing.prefered_type.name}</p> {period}</div>}
                {pairing.prefered_type && pairing.prefered_type.code==="hybride"&& <div className="flex items-center space-x-2 text-sm text-pink-600"><FiUsers/><FiWifi/> <p>{pairing.prefered_type.name}</p> {period}</div>}
                {pairing.subjects && <p className='text-gray-500 text-sm'>{pairing.subjects.map(d=>d.name).join(', ')}</p>}
          </div>
}
export function AADInfoCell({aad_cancelled, aad_choked, aad_success, aad_tipped, period_used}){
  const { t } = useTranslation("common");
  return <div className="flex items-center space-x-1 w-full ">
             <Tooltip  
                  content={t("aad-success-tooltip", {period: period_used && period_used.slug, count: aad_success})}
                      color="light" 
                      delay={0} 
                      className="fixed translate-y-1">
                  <span className={classnames("relative rounded-md bg-green-500 text-white px-2 py-1", !aad_success && "bg-green-500/40" )}>{aad_success? aad_success: 0}</span>
                </Tooltip>
                    
                <Tooltip  
                  content={t("aad-tipped-tooltip", {period: period_used && period_used.slug, count: aad_tipped})}
                      color="light" 
                      delay={0} 
                      className="fixed translate-y-1">
                  <span className={classnames("relative rounded-md bg-yellow-500 text-white px-2 py-1", !aad_tipped && "bg-blue-500/40" )}>{aad_tipped? aad_tipped: 0}</span>
                </Tooltip>
                 <Tooltip  
                  content={t("aad-choked-tooltip", {period: period_used && period_used.slug, count: aad_choked})}
                      color="light" 
                      delay={0} 
                      className="fixed translate-y-1">
                  <span className={classnames("relative rounded-md bg-red-500 text-white px-2 py-1", !aad_choked && "bg-red-500/40" )}>{aad_choked? aad_choked: 0}</span>
                </Tooltip>

              {/* Technical reason */}
                <Tooltip  
                  content={t("aad-cancelled-tooltip", {period: period_used && period_used.slug, count: aad_cancelled})}
                      color="light" 
                      delay={0} 
                      className="fixed translate-y-1">
                  <span className={classnames("relative rounded-md bg-cyan-500 text-white px-2 py-1", !aad_cancelled && "bg-cyan-500/40" )}>{aad_cancelled? aad_cancelled: 0}</span>
                </Tooltip>
            


          </div>
}

function CardsTotal({results}){
  const { t } = useTranslation("common");
  const totalSuccess = results.reduce((acc, d)=>acc+(d.aad_success||0), 0);
  const totalTipped = results.reduce((acc, d)=>acc+(d.aad_tipped||0), 0);
  const totalChoked = results.reduce((acc, d)=>acc+(d.aad_choked||0), 0);
  const totalCancelled = results.reduce((acc, d)=>acc+(d.aad_cancelled||0), 0);

  return <div className='w-full @container'>
    <div className="grid grid-cols-2 gap-4 @[700px]:grid-cols-4">
           <StatsCardSmall title={t("aad-success")} className={"border-t-4 !rounded-none shadow-none !bg-gray-100 h-full border-green-500"}>
                <StatsCardScoreSmall title={totalSuccess || 0}/>
            </StatsCardSmall>
            <StatsCardSmall title={t("aad-tipped")} className={"border-t-4 !rounded-none shadow-none !bg-gray-100 h-full border-yellow-500"}>
                <StatsCardScoreSmall title={totalTipped || 0}/>
            </StatsCardSmall>
            <StatsCardSmall title={t("aad-choked")} className={"border-t-4 !rounded-none shadow-none !bg-gray-100 h-full border-red-500"}>
                <StatsCardScoreSmall title={totalChoked || 0}/>
            </StatsCardSmall>
            <StatsCardSmall title={t("aad-cancelled")} className={"border-t-4 !rounded-none shadow-none !bg-gray-100 h-full border-cyan-500"}>
                <StatsCardScoreSmall title={totalCancelled || 0}/>
            </StatsCardSmall>
        </div>
        </div>
}

export function TutoringGrid({team, tutor, student, pairing, preset, weekdays, studyCode, numWeeks=5, stepDays=7, showTotalInCards=false}){
  const { t } = useTranslation("common");
  const [activePairings, setActivePairings] = useState();
  const [date, setDate] = useState(timeWeek.offset(timeMonday.floor(new Date()), 1));
  const [period, setPeriod] = useState(getFullCurrentPeriod());
  const {fromDate, toDate} = useWeek(date, {weeks: -numWeeks, floorInput:false});
  const teamMemberParams = useMemo(()=>({groupBy:"pairing", 
                        activePairings: activePairings && activePairings.value, 
                        studyCode, 
                        preset: team? null: preset, 
                        team: team && team.slug, 
                        weekdays,
                        fromDate, 
                        period,
                        tutor,
                        pairing,
                        student,
                        toDate, 
                        stepDays, 
                        datePartition: true}),
                     [fromDate, period, weekdays, tutor, pairing, student, preset &&preset.id, activePairings, team]);
  const [results, {error, loading}] = useSearch(searchGroupedStudySessions, teamMemberParams, {immediate: true, limit:5000});


  const headers = useMemo(()=>{
    if (!results || results.length===0) return [];
    var h = [{
                title: t("students"),
                field: (d=>d?d: "student"),
                FormatComponent: StudentCell,

            },
            {
              title: t("aad"),
              field: (d=>d?d: "aad"),
              FormatComponent: AADInfoCell,

          },
            {
              title: t("info"),
              field: (d=>d?d: "info"),
              FormatComponent: InfoCell,

          }];
   
    // Add an header for each date
    results[0].results.forEach((e,i)=>{
      const header = {
        title: `${locale.format("%d %b")(timeDay.offset(new Date(e.date), 1))}`,
        field: (d=>d? ({column: i, 
                      numColumns: results[0].results.length,
                      pairing: d.pairing, 
                      date: d.results[i].date, 
                      studySessions: d.results[i].study_sessions, 
                      fails: d.results[i].fails}): `date-${i}`),
        headerId: locale.format("%d %B")(new Date(e.date)),
        format: (d=><Cell preset={preset} position="center" {...d}/>),
        className: "text-center whitespace-nowrap px-4",
        itemClassName: "pl-0 pr-0 "
      }
      h.push(header)
    })

    h.push({
                title: "",
                field: (d=>d?d: "settings"),
                FormatComponent: SettingsCell,

            })
    return h;
  }, [results])

  return <div className="relative space-y-3 rounded-lg">
          {showTotalInCards && results && results.length>0 && <CardsTotal results={results}/>}
          <div className="flex items-center justify-between px-5">
          <ChangeWeek date={date} setDate={setDate} offset={2} numWeeks={numWeeks} loading={loading}/>
            <div className="flex items-center space-x-3 ">
              {!pairing && <SelectActivePairing  value={activePairings} setValue={setActivePairings}/>}
              <SelectPeriods value={period} setValue={setPeriod}/>
            </div>
          </div>
          {error && <Error.Text {...error}/>}
          {!results?
            <Skeleton className="w-full "/>:
            results.length===0?
              <EmptyState title={t("empty-state.no-tutoring-grid-title", {period: period && period.slug, count: 1+!!period})} 
              description={t("empty-state.no-tutoring-grid-description", {period: period && period.slug, count: 1+!!period})}/>:
            <div className="relative w-full ">
              <Table headers={headers} data={results}  indexingKey={(d=>`${d.pairing.id}`)} bodyClassName={"z-0"} headerRowClassName={"sticky top-0 bg-white"}/>
            </div>
          }

        </div>
}
