import React from 'react';
import classnames from 'classnames';

// Hooks
import { useState, useEffect } from 'react'


// Components
import { SearchField } from 'components/tools/search_field'; 
import { useTranslation } from 'react-i18next';

// API
import { searchPairings } from 'api/pairings';
import { useSearch } from 'hooks/useSearch';

export function SearchPairings({label, value, setValue, disabled, color, size, student, tutor, active, targetWidth}){
  const { t } = useTranslation('common');
  const [params, setParams] = useState({student, tutor, active});
  const [pairings, {loading}] = useSearch(searchPairings, params);

  useEffect(()=>{
    setParams(d=>({...d, student, tutor, active}))
  }, [active, student, tutor]);

  return <SearchField values={pairings}
                      loading={loading}
                      value={value}
                      setValue={setValue}
                      disabled={disabled}
                      color={color}
                      size={size}
                      label={label}
                      placeholder={t("pairing")}
                      indexingField={"id"}
                      targetWidth={targetWidth}
                      formatSelectedValue={(d=>(d.student && d.student.name)?`${d.student.name} / ${d.tutor.name}`: `Jumelé avec ${d.tutor.name}`)}
                      formatSearchResult={((d, {selected, active})=>
                        <div>
                          <span className={classnames('block max-w-md truncate', (active || selected) && 'font-semibold')}>{d.student.name} / {d.tutor.name}</span>
                          {d.ended_at?
                            <span className={classnames('block', !(active || selected) && 'text-gray-500')}>{t("ended", {context: "male"})}</span>:
                          <span className={classnames('block', !(active || selected) && 'text-gray-500')}>{t("active", {context: "male"})}</span>}
                        </div>)}
                     onParamsChange={setParams}
  />
}
