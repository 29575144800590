import React from 'react';

// Components
import { Outlet } from "react-router-dom";
import { PairingLayout } from 'components/pairings/layout';

// Hooks

function PairingBase(){
  return <PairingLayout>
            <Outlet/>
          </PairingLayout>
}
import {default as empty } from "./empty";
import {default as overview} from 'pages/pairing/overview';
import {default as notes} from 'pages/pairing/notes';
export const PairingPages = {root: PairingBase, empty, overview, notes}