import React, { useCallback, useState } from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { Info } from 'components/core/info';
import { SelectCronPeriod } from 'components/tools/select_cron_period';
import { ModifiableSelectField, SingleLineField } from 'components/tools/modifiable_fields';
import { SelectPairingType } from 'components/tools/select_pairing_type';
import { CheckBox } from 'components/tools/checkbox';
import { SelectDate } from 'components/tools/select_date';

// Hooks
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';

// Contexts
import { PairingContext } from 'contexts/pairing';

// API
import { updatePairing, deletePairing } from 'api/pairings';

// Utils
import { locale, dateParse} from 'utils/locale';
import { SelectSchoolSubjectsDropdown } from 'components/tools/select_school_subjects';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useAPI } from 'hooks/useAPI';
import { Button } from 'components/core/button';
import { FiLock } from 'react-icons/fi';
import classNames from 'classnames';
import { Error } from 'components/core/typo';


function DeleteButton({ id }) {
      const { t } = useTranslation('common');
      const [lock, setLock] = useState(true);
      const navigate = useNavigate();
    
      const handleDelete = useCallback(() => {
        navigate(`/pairings`);
        toast.success(t("delete-pairing.success"), { duration: 3000 })
      }, [])
    
      const [, { execute, loading, error }] = useAPI(deletePairing,
        { id },
        { onResult: handleDelete, immediate: false })
    
      return <div className="relative">
        {lock && <div className="absolute z-10 flex items-center justify-center w-full h-full text-center">
          <div>
            <h3 className="pb-3 text-lg text-gray-600">{t("delete-pairing.title")}</h3>
            <Button color="deleteContrast" size="lg" onClick={() => setLock(false)}><FiLock /> {t("delete-pairing.unlock-section")}</Button>
          </div>
        </div>}
        <div className={classNames(" rounded-lg p-3 w-full space-y-3", lock ? "opacity-50 blur-sm" : "border-2 border-red-500")}>
          <div>
            <h3 className="font-medium text-red-600">{t("delete-pairing.title")}</h3>
            <p className="text-gray-500">{t("delete-pairing.description")}</p>
          </div>
          <Button disabled={lock} loading={loading} onClick={execute} block color="deleteContrast" size="lg" className="whitespace-nowrap">{t("delete-pairing.button")}</Button>
          {error ? <Error.Text {...error} /> : null}
        </div>
      </div>
    }
    
export function PairingSettings(){
  const {pairing, setPairing} = useContext(PairingContext);
  const modify = true;
  const { t } = useTranslation('common');

  const id = pairing.id;
  if (!pairing.student){
    return <Skeleton className="h-80"/>
  }
  return <div className="min-h-screen md:min-h-0"> 
            <h1 className="info-section">{t('general-settings')}</h1>

            <Info.Container modify={modify}>

              <ModifiableSelectField 
                    Field={CheckBox} 
                    label={t("student-has-confirmed-pairing")} 
                    modify={true}
                    marker="select"
                    value={pairing.student_confirmed} 
                    setValue={(studentConfirmed)=>{updatePairing({id, studentConfirmed}); setPairing(d=>({...d, student_confirmed: studentConfirmed}))}}/>
                <ModifiableSelectField 
                    Field={CheckBox} 
                    label={t("tutor-has-confirmed-pairing")} 
                    marker="select"
                    modify={true}
                    value={pairing.tutor_confirmed} 
                    setValue={(tutorConfirmed)=>{updatePairing({id, tutorConfirmed}); setPairing(d=>({...d, tutor_confirmed: tutorConfirmed}))}}/>
              <ModifiableSelectField 
                    Field={SelectCronPeriod} 
                    label={modify&&t("weekly-period")} 
                    modify={modify} 
                    value={pairing.period} 
                    setValue={(period)=>{updatePairing({id, period}); setPairing(d=>({...d, period}))}}/>
              <ModifiableSelectField 
                    Field={SelectPairingType} 
                    label={modify&&t("pairing-type")} 
                    modify={modify} 
                    value={pairing.prefered_type} 
                    setValue={(preferedType)=>{updatePairing({id, preferedType: preferedType && preferedType.code}); setPairing(d=>({...d, prefered_type:preferedType}))}}/>

            <ModifiableSelectField 
                    Field={SelectSchoolSubjectsDropdown} 
                    label={t("pairing-subjects")} 
                    modify={modify}
                  //   block={true}
                    value={pairing.subjects || []} 
                    setValue={(subjects)=>{updatePairing({id, subjects}); setPairing(d=>({...d, subjects}))}}/>
                        
              <ModifiableSelectField 
                    Field={SelectDate} 
                    label={modify&&t("started-at")} 
                    canRemoveSelection={true}
                    modify={modify} 
                    value={pairing.started_at && dateParse(pairing.started_at)} 
                    setValue={(startedAt)=>{updatePairing({id, startedAt}); setPairing(d=>({...d, started_at:startedAt}))}} >
                            {(val)=><Info.Field value={val && locale.format("%d %B %Y")(val)} 
                                        noValueLabel={`${t("none")} ${t("started-at").toLowerCase()}`}
                                        label={t("started-at")}
                                        />}
              </ModifiableSelectField>

              <ModifiableSelectField 
                    Field={SelectDate} 
                    label={t("ended-at")} 
                    subLabel={t("ended-at-description")} 
                    modify={modify} 
                    canRemoveSelection={true}
                    value={pairing.ended_at && dateParse(pairing.ended_at)} 
                    setValue={(endedAt)=>{updatePairing({id, endedAt: endedAt || null}); setPairing(d=>({...d, ended_at:endedAt}))}} >
                            {(val)=><Info.Field value={val && locale.format("%d %B %Y")(val)} 
                                        noValueLabel={`${t("none")} ${t("ended-at").toLowerCase()}`}
                                        label={t("ended-at")}
                                        />}
              </ModifiableSelectField>
               <ModifiableSelectField 
                    Field={CheckBox} 
                    label={t("do-not-pair-again")} 
                    subLabel={t("do-not-pair-again-description")} 
                    modify={true}
                    value={pairing.do_not_pair_again} 
                    setValue={(doNotPairAgain)=>{updatePairing({id, doNotPairAgain}); setPairing(d=>({...d, do_not_pair_again: doNotPairAgain}))}}/>

                <div className="pt-6">
                  <SingleLineField 
                            label={modify&&t("reason-for-end")} 
                            modify={pairing.ended_at} 
                            value={pairing.reason_for_end} 
                            multiLine={true}
                            disabled={!pairing.ended_at}
                            setValue={(reasonForEnd)=>updatePairing({id, reasonForEnd})}>
                    {(val)=><Info.Field value={val} noValueLabel={`${t("none", {context: 'female'})} ${t("reason-for-end").toLowerCase()}`}/>}
                  </SingleLineField>
                  </div>

{/*
              <ModifiableSelectField 
                    Field={CheckBox} 
                    name={t("pairing-has-a-flag")} 
                    description={pairing.flag? locale.format("%d %B %Y")(new Date(pairing.flag)) :null} 
                    modify={true}
                    value={pairing.flag} 
                    setValue={(flag)=>{updatePairing({id, flag}); setPairing(d=>({...d, flag: flag? new Date(): null}))}}/>
*/}
            </Info.Container>

            <Info.Container modify={true} className="my-12">
                        <DeleteButton id={id} />
                  </Info.Container>
         </div>
}
