import React from 'react';
import classnames from 'classnames';

// Components
import { SearchField } from 'components/tools/search_field'; 

// Hooks
import { useTranslation } from 'react-i18next';
import { useSearch } from 'hooks/useSearch';
import { useUser } from 'hooks/useUser';
import { useMemo, useState } from 'react';

// API
import { searchPresets } from 'api/presets';

export function SearchPreset({ value, setValue, label, to, limitToUser, disabled, color, targetWidth, size }){
  const { t } = useTranslation("common");
  const [user] = useUser();
  const [search, setSearch] = useState();

  const params = useMemo(()=>limitToUser? {user: user && user.email, ...search}: {...search} , [search])
  const [presets, {loading}] = useSearch(searchPresets, params);
  return <SearchField values={presets}
                      loading={loading}
                      value={value}
                      setValue={setValue}
                      label={label}
                      disabled={disabled}
                      color={color}
                      size={size}
                      to={to}
                      targetWidth={targetWidth}
                      placeholder={t("presets")}
                      indexingField={"id"}
                      formatSelectedValue={(d=>!d.user? null: limitToUser? (d.name || (d.user && d.user.name)): `${d.user.name}${d.name? ` (${d.name})`: ""}`|| "")}
                      formatSearchResult={((d, {selected, active})=>
                        <div>
                          <span className={classnames('block truncate', (active || selected) && 'font-semibold')}>{limitToUser? (d.name || d.user.name): `${d.user.name}${d.name? ` (${d.name})`: ""}`}</span>
                        </div>)}
                     onParamsChange={setSearch}
  />

}
