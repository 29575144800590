import React from 'react';

// API
import { searchGroupedStudySessions} from 'api/study_sessions';

// Hooks
import { useMemo, useState } from 'react';
import { useSearch } from 'hooks/useSearch';
import { useWeek } from 'hooks/dates/useWeek'
import { useTranslation } from 'react-i18next';
import {cronToString} from 'utils/crons';

// Utils
import classnames from 'classnames';
import { locale, dateParse } from 'utils/locale';
import { timeWeek, timeMonday, timeDay } from 'd3-time';
import {format } from 'd3-format'
const fullDayFormat = locale.format("%d %B %Y");

// Components
import { Button } from 'components/core/button';
import { Dropdown } from 'components/core/dropdown';
import { FiMoreVertical, FiMaximize2, FiChevronLeft, FiChevronRight, FiWifi, FiUsers, FiExternalLink, FiCheck, FiInfo } from 'react-icons/fi';
import { Table } from 'components/core/table';
import { EmptyState } from 'components/core/empty';
import { TooltipContent } from 'components/events/play_time_view'
import { Tooltip } from 'components/core/tooltip'
import { Skeleton } from 'components/core/skeleton';
import { Fragment } from 'react';
import { CreateNoteInModal } from 'components/notes/create_modal';
import { SelectPeriods } from 'components/tools/select_period';
import { getFullCurrentPeriod } from 'utils/period';
import classNames from 'classnames';
import { useDebounceEffect } from 'hooks/useDebounce';
import { Error } from 'components/core/typo';
import { Spinner } from 'components/core/spinner';
import { Info } from 'components/core/info';
import { BeakerIcon, BellAlertIcon, BoltIcon, CameraIcon, ClockIcon, CloudIcon, PencilIcon, VideoCameraSlashIcon } from '@heroicons/react/24/outline';

function SchoolFailedTooltipContent({fails}){
  const { t } = useTranslation("common");
  return <div className={"py-3"}><Info.Container label={t("failed-school-classes")} >
            {Math.min(fails.length, 3)===0 && <Info.Field noValueLabel={t("empty-state.no-failed-classes-title")}/>}
            {fails.map(d=>
              <Info.Field key={d.id} value={d.school_class.name} label={`${d.score|| '-'}% | ${(d.school_class && d.school_class.subject && d.school_class.subject.name) || ""}`}/>)}
          </Info.Container></div>
}

const studyCodeToInit = {
  "recuperation": "R",
  "maison-des-jeunes": "MJ",
  "midi-etude": "ME",
  "tutorat": "T",
  "tutorat-prive": "TP",
  "soiree-etude" : "SE",
  "avec-entraineur": "E",
  "autres": "A",
}

const noteWithIcons = {
  "manque-de-materiel-a-son-aide-aux-devoirs": PencilIcon,
  "aide-aux-devoirs-trop-breve": BoltIcon,
  "absence-denregistrement-a-son-aide-aux-devoirs":VideoCameraSlashIcon,
  "camera-fermee": CameraIcon,
  "les-sujets-etudies-ne-sont-pas-en-echec": BeakerIcon,
  "manque-de-concentration-a-son-aide-aux-devoirs": CloudIcon,
  "retard-a-son-aide-aux-devoirs": ClockIcon,
  "autres": BellAlertIcon
}
export function StudySessionCellContentGeneral({id, code,  session_occurred, cancelled_for_external_reason, technical_problem, notes, length_in_minutes}){
  
  const chokeNote = useMemo(()=>{
    if (!notes || !notes.length) return;
    const n = notes.filter(d=>d.code.code.includes("rencontre-manquee-sans-avertissement"));
    if (n.length===0) return;
    return n[0];
   }, [notes])

 const cancelNote = useMemo(()=>{
    if (!notes || !notes.length) return;
    const n = notes.filter(d=>d.code.code.includes("rencontre-annulee"));
    if (n.length===0) return;
    return n[0];
 }, [notes])

 const chokeWithReasonNote = useMemo(()=>{
    if (!notes || !notes.length) return;
    const n = notes.filter(d=>d.code.code.includes("rencontre-manquee-pour"));
    if (n.length===0) return;
    return n[0];
 }, [notes])

 

 return <div className={classnames(
             chokeNote? "bg-red-100 text-red-700":
             cancelNote? "bg-yellow-100 text-yellow-700":
             cancelled_for_external_reason? "bg-blue-100 text-blue-700":
              technical_problem? "bg-blue-100 text-blue-700":
             chokeWithReasonNote? "bg-red-100 text-red-700":
             !session_occurred? "bg-yellow-100 text-yellow-700": // Catch others
              notes.length>0? "bg-purple-100 text-purple-700":
              "bg-green-100 text-green-700",
             "my-1 py-1 px-2 mx-auto rounded relative ")}>
          <Button href={`/study-sessions/${id}`}> <span className=''>{length_in_minutes}</span><span className='font-bold ml-1'>{studyCodeToInit[code && code.code]|| "A"}</span></Button>
          <div className='flex items-center space-x-2'>{
            Object.keys(noteWithIcons).map(d=>{
              const hasNotes = (notes || []).filter(e=>e.code.code===d);
              const Icon = noteWithIcons[d] || BellAlertIcon;
              // -1 because it counts the note itself
              return hasNotes.length>0? <span className='text-sm'>{hasNotes[0].note_count}<Icon className="h-4 w-4 inline  "/></span>: null;
            })
          }
          </div>
        </div>
}


export function Cell({ studySessions, fails, position="center"}){
  
  const diff = (studySessions || []).filter(d=>d.session_occurred).length - Math.min(3, (fails || []).length);
  return <div className="flex items-center justify-between space-x-3 px-3">
          <div className={classnames("flex justify-center items-center whitespace-nowrap space-x-3 ")}>
          {(studySessions || []).sort((a,b)=>dateParse(a.period)-dateParse(b.period)).map(d=>{
            return <div key={d.id} className="">
                    <Tooltip  
                      content={<TooltipContent studySessions={[d]} fails={fails}/>} 
                      position={position}
                      color="light" 
                      delay={0} 
                      className="fixed translate-x-6">
                       <div  className='relative'><StudySessionCellContentGeneral {...d}/></div>
                    </Tooltip>
                   
                  </div>}
          )}
          </div>
          <Tooltip  
            content={<SchoolFailedTooltipContent fails={fails}/>} 
            position={"right"}
            color="light" 
            delay={0} 
            className="fixed translate-x-2">
              <span className={classNames("relative", diff===0? 'text-gray-300': diff<0? 'text-red-500 font-medium ': "font-medium text-blue-500 ")}>{diff===0? <FiCheck/>: format("+")(diff)}</span>
          </Tooltip>

        </div>
}

export function StudentCell({student}){
  return <div className="flex flex-col my-2 whitespace-nowrap">
          <Button target="_blank"  color="hiddenLink" className="font-medium" href={`/students/${student.ni}`}>{student.name}</Button>
          </div>
}

export function SettingsCell({student, pairing}){
  const { t } = useTranslation("common");
  const [showAddNote, setShowAddNote] = useState(false)
  return  <Fragment>
            <Dropdown onlyIcon={<FiMoreVertical/>} itemClassName="whitespace-nowrap" orientation="right">
              <Dropdown.Item onClick={()=>setShowAddNote(true)} name={t('new-note')} icon={<FiMaximize2/>} color="default"/>
              <Dropdown.Item href={`/pairings/${pairing.id}`} name={t('jump-to-pairing')} icon={<FiExternalLink/>} color="default"/>
            </Dropdown>
            <CreateNoteInModal open={showAddNote} setOpen={setShowAddNote} creatorProps={{defaultStudent: student, defaultPairing: {...pairing, student}}}/>

          </Fragment>
}
export function InfoCell({pairing}){
  const period = <p className="text-sm text-gray-500">{cronToString(pairing.period)}</p>;
  return <div className="my-2 whitespace-nowrap">
                 {pairing.ended_at && <p className="text-sm text-gray-500">{locale.format("%d %B")(dateParse(pairing.started_at))} au {locale.format("%d %B")(dateParse(pairing.ended_at))}</p>}
                {!pairing.ended_at && <p className="text-sm text-gray-500">Depuis le {locale.format("%d %B")(dateParse(pairing.started_at))}</p>}
                {pairing.prefered_type && pairing.prefered_type.code==="en-personne"&& <div className="flex items-center space-x-2 text-sm text-gray-700"><FiUsers/> <p>{pairing.prefered_type.name}</p> {period}</div>}
                {pairing.prefered_type && pairing.prefered_type.code==="en-ligne"&& <div className="flex items-center space-x-2 text-sm text-green-600"><FiWifi/> <p>{pairing.prefered_type.name}</p> {period}</div>}
                {pairing.prefered_type && pairing.prefered_type.code==="hybride"&& <div className="flex items-center space-x-2 text-sm text-pink-600"><FiUsers/><FiWifi/> <p>{pairing.prefered_type.name}</p> {period}</div>}
                {pairing.subjects && <p className='text-gray-500 text-sm'>{pairing.subjects.map(d=>d.name).join(', ')}</p>}
          </div>
}

function Legend({className}){
  const { t } = useTranslation("common");
  return <div className={classnames(className)}>
          <div className='flex items-center space-x-2'>
            <span className="!h-5 !w-5 inline bg-red-100 rounded-md border-2 border-red-700 "/>
            <span className='text-gray-800 '>{t(`note-code-aad.red-block`)}</span>
          </div>
          <div className='flex items-center space-x-2'>
            <span className="!h-5 !w-5 inline bg-green-100 rounded-md border-2 border-green-700 "/>
            <span className='text-gray-800 '>{t(`note-code-aad.green-block`)}</span>
          </div>
          <div className='flex items-center space-x-2'>
            <span className="!h-5 !w-5 inline bg-yellow-100 rounded-md border-2 border-yellow-700 "/>
            <span className='text-gray-800 '>{t(`note-code-aad.yellow-block`)}</span>
          </div>
          <div className='flex items-center space-x-2'>
            <span className="!h-5 !w-5 inline bg-purple-100 rounded-md border-2 border-purple-700 "/>
            <span className='text-gray-800 '>{t(`note-code-aad.purple-block`)}</span>
          </div>

          <hr/>
          {Object.keys(noteWithIcons).map(d=>{
            const Icon = noteWithIcons[d] || BellAlertIcon;
            return <div className='flex items-center space-x-2' key={d}>
                    <Icon className="!h-6 !w-6 inline text-purple-500 "/>
                    <span className='text-gray-800 '>{t(`note-code-aad.${d}`)}</span>
                  </div>
          })}
          <hr/>
            <span className="p-1 bg-purple-100 rounded-md text-sm text-purple-600 mr-2 inline-flex items-center  ">
              <span>2</span><BellAlertIcon className='h-4 w-4 inline-text'/>
            </span>
            <span className='text-gray-800 '>{t(`note-code-aad.explication-block`)}</span>
            <span className="p-1 bg-green-100 rounded-md text-sm text-green-600 mr-2 inline-flex items-center  ">
              <span>55</span><span className='font-bold'>R</span>
            </span>
            <span className='text-gray-800 '>{t(`note-code-aad.explication-block-2`)}</span>

          <hr/>
          {Object.keys(studyCodeToInit).map(d=>{
            return <div className='flex items-center space-x-2' key={d}>
                    <span className='text-gray-800 font-bold w-8'>{studyCodeToInit[d]}</span>
                    <span className='text-gray-800 '>{t(`study-code-type.${d}`)}</span>
                  </div>
          })}
          
        </div>
}


function ExpandedLegend(){
  const { t } = useTranslation("common");
  return <div className={classnames("w-full p-3 hidden md:grid gap-3 grid-cols-2 lg:grid-cols-4")}>
          <div className='space-y-1'>
            <div className='flex items-center space-x-2'>
              <span className="!h-5 !w-5 inline bg-red-100 rounded-md border-2 border-red-700 "/>
              <span className='text-gray-800 '>{t(`note-code-aad.red-block`)}</span>
            </div>
            <div className='flex items-center space-x-2'>
              <span className="!h-5 !w-5 inline bg-green-100 rounded-md border-2 border-green-700 "/>
              <span className='text-gray-800 '>{t(`note-code-aad.green-block`)}</span>
            </div>
            <div className='flex items-center space-x-2'>
              <span className="!h-5 !w-5 inline bg-yellow-100 rounded-md border-2 border-yellow-700 "/>
              <span className='text-gray-800 '>{t(`note-code-aad.yellow-block`)}</span>
            </div>
            <div className='flex items-center space-x-2'>
              <span className="!h-5 !w-5 inline bg-purple-100 rounded-md border-2 border-purple-700 "/>
              <span className='text-gray-800 '>{t(`note-code-aad.purple-block`)}</span>
            </div>
          </div>

          <div className='space-y-1'>
            {Object.keys(noteWithIcons).map(d=>{
              const Icon = noteWithIcons[d] || BellAlertIcon;
              return <div className='flex items-center space-x-2' key={d}>
                      <Icon className="!h-6 !w-6 inline text-purple-500 "/>
                      <span className='text-gray-800 '>{t(`note-code-aad.${d}`)}</span>
                    </div>
            })}
          </div>

          <div className='space-y-1'>
            <div className='flex items-center space-x-2'>
              <span className="p-1 bg-purple-100 rounded-md text-sm text-purple-600 mr-2 flex items-center  ">
                <span>2</span><BellAlertIcon className='h-4 w-4 inline-text'/>
              </span>
                <span className='text-gray-800 '>{t(`note-code-aad.explication-block`)}</span>
            </div>
            <div className='flex items-center space-x-2'>
              <span className="p-1 bg-green-100 rounded-md text-sm text-green-600 mr-2 flex items-center  ">
                <span>55</span><span className='font-bold'>R</span>
              </span>
              <span className='text-gray-800 '>{t(`note-code-aad.explication-block-2`)}</span>
            </div>
          </div>

          <div className='space-y-1'>
          {Object.keys(studyCodeToInit).map(d=>{
            return <div className='flex items-center space-x-2' key={d}>
                    <span className='text-gray-800 font-bold w-8'>{studyCodeToInit[d]}</span>
                    <span className='text-gray-800 '>{t(`study-code-type.${d}`)}</span>
                  </div>
          })}
          </div>
 
        </div>
}
export function ChangeWeek({ date, setDate, offset=1, numWeeks=5, leftApply, rightApply, loading}={}){
  const [subDate, setSubDate] = useState(date);
  const {fromDate, toDate} = useWeek(subDate, {weeks: -numWeeks, floorInput:false, weekDayOffset:1});
  useDebounceEffect(subDate, setDate, 500);
  
  return <div className="">
            <div className="flex items-center space-x-3">
            <Button className="px-2 py-2 rounded-full hover:bg-gray-100 flex-0" color="gray" size="smToolbarIcon" layout="none" onClick={()=>setSubDate(timeWeek.offset(subDate, -offset))}><FiChevronLeft/></Button>
              <Button className="px-2 py-2 rounded-full hover:bg-gray-100 flex-0" color="gray" size="smToolbarIcon" layout="none" onClick={()=>setSubDate(timeWeek.offset(subDate, offset))}><FiChevronRight/></Button>
              <Button color="gray" size="smTight2" onClick={()=>setSubDate(new Date())}>
                {fullDayFormat(new Date())}
              </Button>
            </div>
            <span className='text-gray-400 p-1 text-sm '>{locale.format("%d %B ")(leftApply?leftApply(fromDate) :fromDate) + " - " + locale.format("%d %B %Y")(rightApply?rightApply(toDate): toDate)}{loading && <Spinner className="inline ml-2" size="xs"/>}</span>
        </div>
}

export function StudySessionsGrid({team, tutor, student, pairing, preset, numWeeks=5}){
  const { t } = useTranslation("common");
  const [date, setDate] = useState(timeWeek.offset(timeMonday.floor(new Date()), 1));
  const [period, setPeriod] = useState(getFullCurrentPeriod());
  const {fromDate, toDate} = useWeek(date, {weeks: -numWeeks, floorInput:false});
  const params = useMemo(()=>({groupBy:"student-date", 
                        preset: team? null: preset, 
                        team: team && team.slug, 
                        fromDate, 
                        period,
                        tutor,
                        pairing,
                        student,
                        toDate, 
                        stepDays: 7, 
                        datePartition: true}),
                     [fromDate, period, tutor, pairing, student, preset &&preset.id, team]);
  const [results, {loading, error}] = useSearch(searchGroupedStudySessions, params, {immediate: true, limit:5000});


  const headers = useMemo(()=>{
    if (!results || results.length===0) return [];
    var h = [{
                title: t("students"),
                field: (d=>d?d: "student"),
                FormatComponent: StudentCell,
                itemClassName: "border-r  "

            }];

      // Add an header for each date
    results[0].results.forEach((e,i)=>{
      const header = {
        title: `${locale.format("%d %b")(timeDay.offset(new Date(e.date), 1))}`,
        field: (d=>d? ({column: i, 
                      numColumns: results[0].results.length,
                      date: d.results[i].date, 
                      isCurrentWeek: timeWeek.count(new Date(e.date), new Date())==0,
                      studySessions: d.results[i].study_sessions, 
                      fails: d.results[i].fails}): `date-${i}`),
        headerId: locale.format("%d %B")(new Date(e.date)),
        format: (d=><Cell preset={preset} position="center" {...d}/>),
        className: "text-center whitespace-nowrap px-4 w-1/6",
        itemClassName: (({isCurrentWeek})=> isCurrentWeek? "pl-0 pr-0  bg-gray-600 bg-opacity-20 border-r ": "pl-0 pr-0 border-r")
      }
      h.push(header)
    })
    
    return h;
  }, [results])

  return <div className="relative space-y-3 rounded-lg">
            <ExpandedLegend />
          
          <div className="flex items-center justify-between px-5 mt-3">
            <ChangeWeek date={date} setDate={setDate} offset={1} loading={loading} numWeeks={numWeeks}/>
            <Dropdown onlyIcon={<FiInfo/>} menuItemsClassName="w-[400px]" >
            <Legend className="p-3 w-full max-w-md space-y-3"/>
            </Dropdown>
            <div className="flex items-center space-x-3 ">
              <SelectPeriods value={period} setValue={setPeriod} />
            </div>
          </div>
          {error && <Error.Text {...error}/>}
          {!results?
            <Skeleton className="h-80 mx-6 "/>:
            results.length===0?
              <EmptyState title={t("empty-state.no-tutoring-grid-title", {period: period && period.slug, count: 1+!!period})} 
              description={t("empty-state.no-tutoring-grid-description", {period: period && period.slug, count: 1+!!period})}/>:
            <div className="relative w-full ">
              <Table headers={headers} 
              data={results}  
              rowClassName={"odd:bg-gray-50 hover:bg-gray-100"}
              indexingKey={(d=>`${d.student.ni}`)} bodyClassName={"z-0"} headerRowClassName={"sticky top-0 bg-white"}/>
            </div>
          }

        </div>
}
