import React from 'react';

// Components
import { InfoSectionHeading  } from 'components/core/typo';
import { Error } from 'components/core/typo';
import { Container } from 'components/core/container';
import { Table } from 'components/core/table';
import { Skeleton } from 'components/core/skeleton';
import { Dropdown } from 'components/core/dropdown';
import { CreatePairingModal } from 'components/automatic-pairing/create_pairing';
import { Button } from 'components/core/button';
import { FiUsers, FiWifi, FiMoreHorizontal, FiPlus } from 'react-icons/fi';

// Hooks
import { useAPI } from 'hooks/useAPI';
import { useParams } from 'react-router-dom';
import { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

// Utils
import { cronsIntervalToString } from 'utils/crons';
import { exportCSVFile } from 'utils/export_csv';
import { locale, dateParse } from 'utils/locale';
// API
import { retrieveDraftPairingResults } from 'api/auto-pairing';
import { EmptyState } from 'components/core/empty';
import { getCurrentPeriod } from 'utils/period';
import { QualityCell } from './test';

export function PreferenceCell({student, tutor}){
  const { t } = useTranslation("autoPairing");

  return <div className="whitespace-nowrap">
          {student.on_site_preference &&tutor.on_site_preference&&
           <p className="flex items-center space-x-2 text-sm text-gray-700"><FiUsers/> <span>{t("preferences.onsite")}</span></p>}
          {student.online_preference && tutor.online_preference&& 
            <p className="flex items-center space-x-2 text-sm text-green-600"><FiWifi/> <span>{t("preferences.online")}</span></p>}
          {student.hybrid_preference && tutor.hybrid_preference&& 
            <p className="flex items-center space-x-2 text-sm text-pink-600"><FiUsers/><FiWifi/> <span>{t("preferences.hybrid")}</span></p>}
          {(student.hybrid_preference && !tutor.hybrid_preference)?
            tutor.on_site_preference?
              <>
              <p className="flex items-center space-x-2 text-sm text-pink-600"><FiUsers/><FiWifi/> <span>{t("preferences.hybrid")}</span></p>
              <p className="flex items-center space-x-2 text-sm text-gray-700"><FiUsers/> <span>{t("preferences.onsite")}</span></p>
              </>:
            tutor.online_preference?
               <>
                <p className="flex items-center space-x-2 text-sm text-pink-600"><FiUsers/><FiWifi/> <span>{t("preferences.hybrid")}</span></p>
                <p className="flex items-center space-x-2 text-sm text-green-600"><FiWifi/> <span>{t("preferences.online")}</span></p>
              </>:null:null
          }
          {(tutor.hybrid_preference && !student.hybrid_preference)?
            student.on_site_preference?
              <>
              <p className="flex items-center space-x-2 text-sm text-pink-600"><FiUsers/><FiWifi/> <span>{t("preferences.hybrid")}</span></p>
              <p className="flex items-center space-x-2 text-sm text-gray-700"><FiUsers/> <span>{t("preferences.onsite")}</span></p>
              </>:
            student.online_preference?
              <>
                <p className="flex items-center space-x-2 text-sm text-pink-600"><FiUsers/><FiWifi/> <span>{t("preferences.hybrid")}</span></p>
                <p className="flex items-center space-x-2 text-sm text-green-600"><FiWifi/> <span>{t("preferences.online")}</span></p>
              </>:null:null
          }
          </div>
}

function EditCell({setResult, ...draft}){
  const { t } = useTranslation("autoPairing");
  const [open, setOpen] = useState(false);
  return <>
          <Dropdown onlyIcon={<FiMoreHorizontal/>} itemClassName="whitespace-nowrap">
          <Dropdown.Item onClick={()=>setOpen(true)}
                        icon={<FiPlus/>}
                        name={t("create-pairing.button")}/>
         </Dropdown>
          <CreatePairingModal 
              open={open} 
              setOpen={setOpen} 
              draft={draft} 
              onResult={(pairing)=>setResult(k=>({...k, pairings:k.pairings.map(d=>((d.student.ni===pairing.student.ni)&&(d.tutor.ni===pairing.tutor.ni))?({...d, active_pairings: [...d.active_pairings, pairing]}) :d)}))}/>
          </>
}


function ActivePairings(draft){
  return <Dropdown label={`${draft.active_pairings.length}`} menuClassName="py-1" orientation="left" menuItemsClassName="w-48 max-w-screen" 
            color={draft.active_pairings.length>0? "defaultDarker": "default"}>
          {draft.active_pairings.length===0 && <Dropdown.Item name="Aucun jumelage" disabled={true}/>}
          {draft.active_pairings.map(d=>
            <Dropdown.Item 
              key={d.id} 
              href={`/pairings/${d.id}`} 
              name={`Créé le ${locale.format("%d %B")(dateParse(d.started_at))}`} 
              description={d.ended_at? `Terminé le ${locale.format("%d %B")(dateParse(d.ended_at))}`: `` }/>)}
         </Dropdown>
}

export function Pairings(){
  const { t } = useTranslation("autoPairing");
  const currentPeriod = getCurrentPeriod();
  const params = useParams();
  const [draft, {error, setResult}] = useAPI(retrieveDraftPairingResults, params, {validateParams: ((d)=>!!d.draftId)})
  const headers = useMemo(()=>[

      {
        title: t("exists"),
        field: (d=>d?d: "exists"),
        itemClassName: " py-2 my-2 max-w-md text-gray-500",
        className: "px-2 my-2 py-1",
        FormatComponent: ActivePairings,
        ignoreAtExport: true
      },
      {
        title:"",
        field: (d=>d? d.student.prioritary_for_tutoring : "prioritary_for_tutoring"),
        format: (d=>d? 'Prioritaire': ''),
        parseNull: true,
        itemClassName: "text-blue-500 "
      },
      {
        title: t("quality"),
        field: (d=>d? d : "quality"),
        format: (d=>d? d.total_quality: '-'),
        FormatComponent: QualityCell,
        itemClassName: "font-medium whitespace-nowrap "
      },
      {
        title: t("student.title"),
        field: (d=>d?d: "student"),
        itemClassName: "font-medium py-2",
        format: (d=>d.student.name)
      },
      {
        title: t("tutor"),
        field: (d=>d?d: "tutor"),
        itemClassName: "font-medium py-1",
        format: (d=>d.tutor.name)
      },
      {
        title: t("student.teams"),
        field: (d=>d?d.student.teams.filter(d=>d.team.active_period===currentPeriod).map(e=>e.team && e.team.name).join(' et '): "teams"),
        itemClassName: "text-gray-600 whitespace-nowrap max-w-xs truncate "
      },
      {
        title: t("student.school-year"),
        field: (d=>d?d.student.school_year.name: "school-year"),
        itemClassName: "text-gray-600 whitespace-nowrap max-w-xs truncate "
      },
      {
        title: t("school"),
        field: (d=>d?d.student.school && d.student.school.name: "school"),
        itemClassName: "text-gray-600  "
      },
      {
        title: t("matched-subjects.title"),
        field: (d=>d?d.matched_subjects: "matched_subjects"),
        itemClassName: " py-1 max-w-md text-gray-500",
        className: "px-2",
        format: (d=>d.filter(s=>s.student_preference.description).map(s=>`${s.name} (${t("matched-subjects.tutor-"+s.tutor_preference.description)}/ ${t("matched-subjects.student-"+s.student_preference.description)})`).join(', '))
      },
      {
        title: t("matched-schedules.title"),
        field: (d=>d?d.matched_schedule: "matched_schedule"),
        itemClassName: " py-1 max-w-md text-gray-500",
        className: "px-2",
        format: (d=>d.map(s=>`${cronsIntervalToString(s)}`).join(', '))
      },
      {
        title: t("preferences.title"),
        field: (d=>d?d: "preferences"),
        itemClassName: " py-1 max-w-md text-gray-500",
        className: "px-2",
        format:(d=>((d.student.on_site_preference &&d.tutor.on_site_preference)? t("preferences.onsite"):
          (d.student.online_preference &&d.tutor.online_preference)? t("preferences.online"):
          (d.student.hybrid_preference &&d.tutor.hybrid_preference)? t("preferences.hybrid"):
          (d.student.hybrid_preference &&d.tutor.on_site_preference)? t("preferences.onsite"):
          (d.student.hybrid_preference &&d.tutor.online_preference)? t("preferences.online"):
          (d.tutor.hybrid_preference &&d.student.on_site_preference)? t("preferences.onsite"):
          (d.tutor.hybrid_preference &&d.student.online_preference)? t("preferences.online"):
          t("preferences.undefined")
          )),
        FormatComponent: PreferenceCell
      },
      {
        title: "",
        field: (d=>d?({...d, setResult}): "actions"),
        itemClassName: " py-2 my-2 max-w-md text-gray-500",
        className: "px-2 my-2 py-1",
        FormatComponent: EditCell,
        ignoreAtExport: true
      },
  ], [setResult])

  const exportFile = useCallback(()=>{
    if (!draft || !draft.pairings) return;
    exportCSVFile(headers, draft.pairings, `jumelage_auto_${draft.id}`)
  }, [draft])


  return <Container>
          <InfoSectionHeading mediumTitle={t("results.title")} longDescription={t("results.description")}>
            <Button color="active" size="md" onClick={exportFile}>
              {t("export-as-csv")}
            </Button>
          </InfoSectionHeading>
          {!draft?
            <Skeleton className="h-80"/>:
            draft.pairings.length===0?
              <EmptyState title={t("results.no-result.title")} 
                  description={t("results.no-result.description")}
              />:
            <Table 
              data={draft.pairings} 
              headers={headers} 
              rowClassName={(d=>d.active_pairings.length>0? "bg-gray-100": "" )}
              indexingKey={d=>`${d.student.ni}-${d.tutor.ni}`}/>
          }
          {error? <Error.Text {...error}/>: null}
         </Container>

}