import React from 'react';

// Components
import { SearchTeams } from 'components/tools/search_teams';
import { SearchPreset } from 'components/tools/search_preset';
import { SelectWeekdays } from 'components/tools/select_weekdays';
import { Button } from 'components/core/button';
import { TutoringFollowUpTabs } from 'components/tutoring-follow-up/tabs';

// Hooks
import { useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
// Context
import { TutoringFollowUpContext } from 'contexts/tutoring_follow_up';

export function FilterBar({presetTo}){
  const location = useLocation();
  const { filters, dispatchFilters } = useContext(TutoringFollowUpContext);
  const teamExtraParams = useMemo(()=>({preset: filters.preset}), [filters && filters.preset])
  return <div className="flex items-center bg-gray-100 flex-shrink-0 border-b h-12 px-6 space-x-3">
            <SearchPreset  
                    to={presetTo} 
                    targetWidth="md" 
                    color="white" 
                    value={filters.preset} 
                    limitToUser={true} 
                    setValue={(value)=>dispatchFilters({type: "preset", value})}/>
            {filters.preset&&
              <SearchTeams 
                  color="white" 
                  value={filters.team} targetWidth="sm" 
                    extraParams={teamExtraParams} 
                    setValue={(value)=>dispatchFilters({type: "team", value})}/>}
            <div className="flex-1"/>
            {filters.preset&& location && location.pathname && !location.pathname.includes("/notes") && 
            <SelectWeekdays value={filters.weekdays} setValue={(value)=>dispatchFilters({type: "weekdays", value})}/>}
            {filters.preset && filters.preset.id && <TutoringFollowUpTabs/>}
          </div>
}

export function TeamListInPreset(){
  const { filters, dispatchFilters } = useContext(TutoringFollowUpContext);
  return filters.preset && filters.preset.teams ?
          <div className="items-center flex-shrink-0  bg-gray-100 border-b py-1 px-6">
            {filters.preset.teams.map(d=><Button key={d.slug} 
                                                color={filters.team && filters.team.slug===d.slug?"active": "gray"}
                                                 size="md" 
                                                  className="inline-flex mr-2 "
                                                 onClick={()=>dispatchFilters({type: 'team', value: (filters.team && filters.team.slug===d.slug)? null: d})}>{d.name}</Button>)}
          </div>: null
}