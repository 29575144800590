import React from 'react';
import classnames from 'classnames';

// API
import { searchPairings } from 'api/pairings';

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Context
import { SearchContext } from 'contexts/search';

// Components
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';
import { Button } from 'components/core/button';
import { ListSearch } from 'components/core/inputs';
import { PaginateCompact } from 'components/core/paginate';

function PairingCell({ id, student, tutor }){
  return <Button className="p-3" layout="cell" nav  color={(d)=>d? "activeCell": "cell"}href={`/pairings/${id}`}>
          {({isActive})=>
            <div className="block">
              <p className={classnames(isActive&& "font-medium text-white")}>{student.name}</p>
              <p className={classnames(isActive? "font-medium text-white": "text-gray-600")}>{tutor.name}</p>
            </div>}
         </Button>
}

export function PairingsList(){
  const { t } = useTranslation("common");
  const { filters, dispatchFilters } = useContext(SearchContext);
  const [pairings, { loading, hasMore, paging, setPage}] = useSearch(searchPairings, filters);

  if (!pairings){
    return <Skeleton.List itemClassName="h-8 rounded" className="space-y-3 p-3" numElements={4}/>
  }
  return <div>
          <div className="border-b">
            <ListSearch color="none"
                loading={loading}
                search={true}
                placeholder={t("search-by-name")}
                onChange={(value)=>dispatchFilters({type: 'search', value})} />
          </div> 
          {pairings.length===0?
            <EmptyState 
                title={t("empty-state.no-pairings-title")} 
                description={t("empty-state.no-pairings-description", {search: filters.search})}/>:
            <div className="divide-y">
            {pairings.map(d=><PairingCell key={d.id} {...d}/>)}
          </div>}
          {paging&& hasMore && <PaginateCompact setPage={setPage} {...paging}/>}

        </div>
}