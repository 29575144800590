import { fetchCall } from 'api';
import { isBool, isString} from 'utils/var_check';

/*
  Create a draft pairing
*/  
export const draftPairing = ({student, tutor}) => {
  const options = {
    method: "POST", 
    endpoint: "/draft-pairing",
    body: {student, 
            tutor}
  }
  return fetchCall(options)
};


/*
  Create a pairing
*/  
export function createPairing({student, 
                               tutor, 
                               studentConfirmed, 
                               tutorConfirmed, 
                               closeCurrentTutorPairing, 
                               closeCurrentStudentPairing, 
                               startedAt, 
                               endedAt, 
                               subjects,
                               preferedType, 
                               period}){
  const options = {
    method: "POST", 
    endpoint: "/pairings",
    body: {student: student && (isString(student)? student: student.ni), 
          tutor: tutor && (isString(tutor)? tutor: tutor.ni), 
          studentConfirmed, 
          tutorConfirmed, 
          startedAt, 
          endedAt, 
          subjects: subjects && subjects.map(d=>d.slug),
          preferedType: preferedType && (isString(preferedType)? preferedType: preferedType.code), 
          period,
          closeCurrentTutorPairing,
          closeCurrentStudentPairing}
  }
  return fetchCall(options)
}


/*
  Delete a pairing
*/
export const deletePairing = ({id}) => {
  const options = {
    method: "DELETE",
    endpoint: `/pairings/${id}`,
  }
  return fetchCall(options)
};

/*
  Update a pairing
*/  
export const updatePairing = ({id, period, subjects, preferedType, reasonForEnd, flag, startedAt, endedAt, doNotPairAgain, studentConfirmed, tutorConfirmed}) => {
  const options = {
    method: "POST", 
    endpoint: `/pairings/${id}`,
    body: {period, preferedType, startedAt, endedAt, reasonForEnd, flag, 
          subjects: subjects && subjects.map(d=>d.slug),
          studentConfirmed, tutorConfirmed, doNotPairAgain}
  }
  return fetchCall(options)
};

/*
  Retrieve a pairing
*/  
export const retrievePairing = ({id}) => {
  const options = {
    method: "GET", 
    endpoint: `/pairings/${id}`,
  }
  return fetchCall(options)
};


/*
  Search pairings
*/  
export const searchPairings = ({search, period, preset, team, school, serviceIsEnded, student, fields, tutor, page, active, activeSinceFrom, activeSinceTo, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET", 
    endpoint: `/pairings`,
    query: {search, 
            student, 
            tutor, 
            fields,
            active: active && (isBool(active)? active: active.value), 
            activePeriod: period && (isString(period)? period: period.slug), 
            serviceIsEnded: serviceIsEnded && (isBool(serviceIsEnded)? serviceIsEnded: serviceIsEnded.value), 
            activeSinceFrom: activeSinceFrom && activeSinceFrom.toISOString(),
            activeSinceTo: activeSinceTo && activeSinceTo.toISOString(),
            preset: preset && preset.id,
            school: school && school.slug,
            team: team && team.slug,
            page, 
            limit, 
            orderBy, 
            orderDirection}
  }
  return fetchCall(options)
};

/*
  Close all active pairings
*/  
export const closeAllActivePairings = ({endedAt, reason}) => {
  const options = {
    method: "POST", 
    endpoint: "/close-all-pairings",
    body: {endedAt: endedAt.toISOString().slice(0,10), reason}
  }
  return fetchCall(options)
};
