import React from 'react';
import classnames from 'classnames';

// Components
import { DashboardLayout, MultiColumn } from 'components/core/layouts';
import { FiGitMerge } from 'react-icons/fi';
import { PairingsList } from 'components/pairings/search'; 
import { FilterBar } from 'components/tools/filter_bar';
import { SectionHeading } from 'components/core/typo';
import { Outlet } from "react-router-dom";

// Contexts
import { SearchContextProvider } from 'contexts/search';

// Hooks
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

// Utils
import { getFullCurrentPeriod } from 'utils/period';

export function PairingListPage(){
  const { id } = useParams();
  const { t } = useTranslation("common")
  const defaultFields = useMemo(()=>({period: getFullCurrentPeriod()}), []); 
  return <SearchContextProvider defaultState={{active: {name: 'Actifs', value:true}}} defaultFields={defaultFields}> 
          <DashboardLayout contentClassName={"overflow-hidden"}>
            <SectionHeading title={t("pairings")} className={classnames(id && "hidden md:block")}>
              <FilterBar period school team preset pairingActive/>
            </SectionHeading>
            <MultiColumn>
              <MultiColumn.Left className={classnames("border-r", id && "hidden md:block")}> 
                <PairingsList/>
              </MultiColumn.Left>
              <MultiColumn.Content  className={classnames(!id && "hidden md:block")}>
                <Outlet/>
              </MultiColumn.Content>
            </MultiColumn>
           </DashboardLayout>
         </SearchContextProvider>
}

const route =  { path: "/pairings", 
                 name: "Jumelages" , 
                 Icon: FiGitMerge,
                 showInNavBar: true, 
                 requireAuth: true, 
                 Element: PairingListPage };
export default route;
