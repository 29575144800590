import React from 'react';

// Components
import { CreatePairing } from 'components/automatic-pairing/create';
import { DraftAutoPairingContextProvider } from 'contexts/draft-auto-pairing';

// Contexts

// Hooks

const defaultState = {
  doNotPairAgain: true, 
  tutorMustBeOkWithParticularity: true, 
  tutorMustBeOkWithAccueil: true,
  shouldHaveCommonSubjects: true,
  shouldHaveCommonSchools: true,
  shouldHaveCommonFormat: true,
  shouldHaveCommonAvailabilities: true,
  shouldHaveCommonSchedule: true,
  studentWithContract: true,
  studentIsPriority: {description: "3"},
  commonSchoolWeight: {description: "3"},
  commonSubjectWeight: {description: "3"},
  hasParticularityWeight: {description: "3"},
  isInAccueilWeight: {description: "3"},
  numberOfClassFailedWeight: {description: "3"},
  pairingExistedBefore: {description: "3"},
  tutorYearsOfServiceWeight: {description: "3"}
};

export default function Create(){
  return <DraftAutoPairingContextProvider defaultState={defaultState}>
          <CreatePairing/>
          </DraftAutoPairingContextProvider>
}
 