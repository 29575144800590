import { fetchCall } from 'api';
import { isString } from 'utils/var_check';

/*
  Search stats
*/  
export const retrieveStudySessionStats = ({fromDate, toDate, showLeftServiceStudents, achieveTarget, stepDays, search, student, school, preset, team, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET", 
    endpoint: "/stats/study-sessions-targets",
    query: {search, 
            fromDate: fromDate && fromDate.toISOString(), 
            toDate: toDate && toDate.toISOString(), 
            stepDays,
            preset: preset && preset.id,
            school: school&&school.slug,
            student: student && (isString(student)? student: student.ni), 
            team: team && (isString(team)? team: team.slug), 
            achieveTarget,
            showLeftServiceStudents,
            page, 
            limit, 
            orderBy, 
            orderDirection}
  }
  return fetchCall(options)
};


/*
  Search stats
*/  
export const retrieveTutoringStats = ({fromDate, activePairings, toDate, period, weekdays, search, achieveTarget, student, school, preset, team, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET", 
    endpoint: "/stats/tutoring-stats",
    query: {search, 
            fromDate: fromDate && fromDate.toISOString(), 
            toDate: toDate && toDate.toISOString(), 
            preset: preset && preset.id,
            school: school&&school.slug,
            period: period && (isString(period)? period: period.slug), 
            student: student && (isString(student)? student: student.ni), 
            team: team && (isString(team)? team: team.slug), 
            activePairings,
            achieveTarget,
            weekdays,
            page, 
            limit, 
            orderBy, 
            orderDirection}
  }
  return fetchCall(options)
};

/*
  Search stats
*/  
export const searchSchoolSubjectsCovered = ({fromDate, toDate, stepDays, search, student, school, preset, team, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET", 
    endpoint: "/stats/school-subjects-covered",
    query: {search, 
            fromDate: fromDate && fromDate.toISOString(), 
            toDate: toDate && toDate.toISOString(), 
            stepDays,
            preset: preset && preset.id,
            school: school&&school.slug,
            student: student && (isString(student)? student: student.ni), 
            team: team && (isString(team)? team: team.slug), 
            page, 
            limit, 
            orderBy, 
            orderDirection}
  }
  return fetchCall(options)
};

/*
  Search stats
*/  
export const searchCoachMeetingStats = ({dates, code, coach, preset, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET", 
    endpoint: "/stats/coach-meeting-stats",
    query: {dates: dates && dates.map(d=>d.toISOString()), 
            preset: preset && preset.id,
            coach: coach && (isString(coach)? coach: coach.ni), 
            code: code && (isString(code)? code: code.code), 
            page, 
            limit, 
            orderBy, 
            orderDirection}
  }
  return fetchCall(options)
};





