import React, {useMemo} from 'react';

// Components
import { ListSelection } from 'components/tools/list_selection';
import { FiToggleRight } from 'react-icons/fi';

// Hooks
import { useTranslation } from 'react-i18next';

export function SelectStatsFiltering({ value, setValue, Icon, disabled, ...props }){

  const { t } = useTranslation("common");
  const values = [
    {
      name: t("students-from-preset.title"),
      longDescription: t("students-from-preset.description"),
      value: "preset"
    },
    {
      name: t("all-students.title"),
      longDescription: t("all-students.description"),
      value: "all"
    }
  ]
  const controlledValue = useMemo(()=>!value? values[0]: !value.value? values.find(d=>d.value === value): value, [value]);
  return <ListSelection value={controlledValue}
                        setValue={setValue}
                        disabled={disabled}
                        values={values}
                        label={t('yes/no')}
                        Icon={Icon || FiToggleRight}
                        getKey={(d)=>d.value}
                        format={(d)=>d.name}
                        withClearSelection={false}
                        {...props}
                        />
}