import React from 'react';

// Routing
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";

// Pages
import { Error404 } from 'pages/errors/404';
import { ErrorBoundary } from 'pages/errors/500';

import { LogoutPage } from 'pages/logout'
import { LoginPage } from 'pages/login'
import { SignupPage } from 'pages/signup'
import { ResetPasswordPage } from 'pages/reset_password';
import { SMSChallengePage } from 'pages/sms_challenge';
import { ForgotPasswordPage } from 'pages/forgot_password';
import { StudentListPage } from 'pages/students';
import { StudentPages } from 'pages/student';
import { StudentSettingsPages } from 'pages/student/settings';
import { TutorListPage } from 'pages/tutors';
import { TutorPages } from 'pages/tutor';
import { TutorSettingsPages } from 'pages/tutor/settings';
import { CoachListPage } from 'pages/coachs';
import { CoachPages } from 'pages/coach';
import { CoachSettingsPages } from 'pages/coach/settings';
import { TeamListPage } from 'pages/teams';
import { TeamPages } from 'pages/team';
import { TeamSettingsPages } from 'pages/team/settings';
import { PairingListPage } from 'pages/pairings';
import { PairingPages } from 'pages/pairing';
import { PairingSettingsPages } from 'pages/pairing/settings';
import { StudySessionListPage } from 'pages/study_sessions';
import { StudySessionPages } from 'pages/study-session';
import { StudySessionSettingsPages } from 'pages/study-session/settings';
import { SettingsPages} from 'pages/settings';
import { DraftAutoPairingListPage } from 'pages/automatic_pairing';
import { AutoPairingPages } from 'pages/auto-pairing';
import { EndYearAssistantPages } from 'pages/end-year-assistant';
import { BulkImportPages } from 'pages/bulk-import';
import { PortalAccessPages } from 'pages/portal-access';
import { RecruitingPages } from 'pages/recruting';
import { TutoringFollowUpPages } from 'pages/tutoring-follow';
import { TerrainFollowUpPages } from 'pages/terrain-follow';
import { HomePage } from 'pages/home';
import { CreateCoachPage } from 'pages/new_coach';
import { CreateTutorPage } from 'pages/new_tutor';
import { CreateStudentPage } from 'pages/new_student';
import { CreateTeamPage } from 'pages/new_team';
import { CreateStudySessionPage } from 'pages/new_study_session';
import { CreateNotePage } from 'pages/new_note';
import { ChangeLogPage } from 'pages/changelog';
import { ExportPage } from 'pages/export';
import { ExportContentParams } from 'components/exports/content';

// Contexts
import { AuthManager } from 'components/core/auth';
import { NoteSliderOverContextProvider } from 'contexts/note-slider';

export default function App(){
  return (<BrowserRouter basename={process.env.PUBLIC_URL}>
            <Routes>
              {/* Public routes */}
              <Route path="/login" element={<LoginPage/>}/>
              <Route path="/logout" element={<LogoutPage/>}/>
              <Route path="/signup" element={<SignupPage/>}/>
              <Route path="/reset-password" element={<ResetPasswordPage/>}/>
              <Route path="/mfa-sms-challenge" element={<SMSChallengePage/>}/>
              <Route path="/forgot-password" element={<ForgotPasswordPage/>}/>

              {/* Private routes */}
              <Route path="/"  element={<ErrorBoundary><AuthManager/></ErrorBoundary>}>
                {/* Standalone routes */}
                <Route path="home" element={<ErrorBoundary><HomePage/></ErrorBoundary>}/>
                <Route path="new-coach" element={<ErrorBoundary><CreateCoachPage/></ErrorBoundary>}/>
                <Route path="new-tutor" element={<ErrorBoundary><CreateTutorPage/></ErrorBoundary>}/>
                <Route path="new-student" element={<ErrorBoundary><CreateStudentPage/></ErrorBoundary>}/>
                <Route path="new-team" element={<ErrorBoundary><CreateTeamPage/></ErrorBoundary>}/>
                <Route path="new-study-session" element={<ErrorBoundary><CreateStudySessionPage/></ErrorBoundary>}/>
                <Route path="new-note" element={<ErrorBoundary><CreateNotePage/></ErrorBoundary>}/>
                <Route path="changelog" element={<ErrorBoundary><ChangeLogPage/></ErrorBoundary>}/>

                {/* Students */}
                <Route path="students" element={<ErrorBoundary><StudentListPage/></ErrorBoundary>}>
                  <Route index element={<ErrorBoundary><StudentPages.empty/></ErrorBoundary>}/>
                  <Route path=":ni" element={<ErrorBoundary><StudentPages.root/></ErrorBoundary>}>
                    <Route index element={<ErrorBoundary><StudentPages.overview/></ErrorBoundary>}/>
                    <Route path="notes" element={<ErrorBoundary><StudentPages.notes/></ErrorBoundary>}/>
                    <Route path="documents" element={<ErrorBoundary><StudentPages.documents/></ErrorBoundary>}/>
                    <Route path="settings" element={<ErrorBoundary><StudentSettingsPages.root/></ErrorBoundary>}>
                      <Route index element={<ErrorBoundary><StudentSettingsPages.general/></ErrorBoundary>}/>
                      <Route path="class-failed" element={<ErrorBoundary><StudentSettingsPages.subjects/></ErrorBoundary>}/>
                      <Route path="professional-comments" element={<ErrorBoundary><StudentSettingsPages.professionalComments/></ErrorBoundary>}/>
                      <Route path="siblings" element={<ErrorBoundary><StudentSettingsPages.siblings/></ErrorBoundary>}/>
                      <Route path="teams" element={<ErrorBoundary><StudentSettingsPages.teams/></ErrorBoundary>}/>
                      <Route path="preferences" element={<ErrorBoundary><StudentSettingsPages.preferences/></ErrorBoundary>}/>
                      <Route path="pairings" element={<ErrorBoundary><StudentSettingsPages.pairings/></ErrorBoundary>}/>
                      <Route path="close" element={<ErrorBoundary><StudentSettingsPages.close/></ErrorBoundary>}/>
                      <Route path="open" element={<ErrorBoundary><StudentSettingsPages.open/></ErrorBoundary>}/>
                    </Route>
                  </Route>
                </Route>

                {/* Tutors */}
                <Route path="tutors" element={<ErrorBoundary><TutorListPage/></ErrorBoundary>}>
                  <Route index element={<ErrorBoundary><TutorPages.empty/></ErrorBoundary>}/>
                  <Route path=":ni" element={<ErrorBoundary><TutorPages.root/></ErrorBoundary>}>
                    <Route index element={<ErrorBoundary><TutorPages.overview/></ErrorBoundary>}/>
                    <Route path="notes" element={<ErrorBoundary><TutorPages.notes/></ErrorBoundary>}/>
                    <Route path="settings" element={<ErrorBoundary><TutorSettingsPages.root/></ErrorBoundary>}>
                      <Route index element={<ErrorBoundary><TutorSettingsPages.general/></ErrorBoundary>}/>
                      <Route path="preferences" element={<ErrorBoundary><TutorSettingsPages.preferences/></ErrorBoundary>}/>
                      <Route path="close" element={<ErrorBoundary><TutorSettingsPages.close/></ErrorBoundary>}/>
                      <Route path="open" element={<ErrorBoundary><TutorSettingsPages.open/></ErrorBoundary>}/>
                      <Route path="portal" element={<ErrorBoundary><TutorSettingsPages.portal/></ErrorBoundary>}/>
                    </Route>
                  </Route>
                </Route>


                {/* Coachs */}
                <Route path="coachs" element={<ErrorBoundary><CoachListPage/></ErrorBoundary>}>
                  <Route index element={<ErrorBoundary><CoachPages.empty/></ErrorBoundary>}/>
                  <Route path=":ni" element={<ErrorBoundary><CoachPages.root/></ErrorBoundary>}>
                    <Route index element={<ErrorBoundary><CoachPages.overview/></ErrorBoundary>}/>
                    <Route path="settings" element={<ErrorBoundary><CoachSettingsPages.root/></ErrorBoundary>}>
                      <Route index element={<ErrorBoundary><CoachSettingsPages.general/></ErrorBoundary>}/>
                      <Route path="close" element={<ErrorBoundary><CoachSettingsPages.close/></ErrorBoundary>}/>
                      <Route path="open" element={<ErrorBoundary><CoachSettingsPages.open/></ErrorBoundary>}/>
                    </Route>
                  </Route>
                </Route>

                {/* Pairings */}
                <Route path="pairings" element={<ErrorBoundary><PairingListPage/></ErrorBoundary>}>
                  <Route index element={<ErrorBoundary><PairingPages.empty/></ErrorBoundary>}/>
                  <Route path=":id" element={<ErrorBoundary><PairingPages.root/></ErrorBoundary>}>
                    <Route index element={<ErrorBoundary><PairingPages.overview/></ErrorBoundary>}/>
                    <Route path="notes" element={<ErrorBoundary><PairingPages.notes/></ErrorBoundary>}/>
                    <Route path="settings" element={<ErrorBoundary><PairingSettingsPages.root/></ErrorBoundary>}>
                      <Route index element={<ErrorBoundary><PairingSettingsPages.general/></ErrorBoundary>}/>
                    </Route>
                  </Route>
                </Route>

                {/* Teams */}
                <Route path="teams" element={<ErrorBoundary><TeamListPage/></ErrorBoundary>}>
                  <Route index element={<ErrorBoundary><TeamPages.empty/></ErrorBoundary>}/>
                  <Route path=":slug" element={<ErrorBoundary><TeamPages.root/></ErrorBoundary>}>
                    <Route index element={<ErrorBoundary><TeamPages.overview/></ErrorBoundary>}/>
                    <Route path="play-time" element={<ErrorBoundary><TeamPages.playTime/></ErrorBoundary>}/>
                    <Route path="play-time/:eventId" element={<ErrorBoundary><TeamPages.playTime/></ErrorBoundary>}/>
                    <Route path="settings" element={<TeamSettingsPages.root/>}>
                      <Route index element={<ErrorBoundary><TeamSettingsPages.general/></ErrorBoundary>}/>
                      <Route path="members" element={<ErrorBoundary><TeamSettingsPages.members/></ErrorBoundary>}/>
                      <Route path="events" element={<ErrorBoundary><TeamSettingsPages.events/></ErrorBoundary>}/>
                    </Route>
                  </Route>
                </Route>

                {/* Study sessions */}
                <Route path="study-sessions" element={<ErrorBoundary><StudySessionListPage/></ErrorBoundary>}>
                  <Route index element={<ErrorBoundary><StudySessionPages.empty/></ErrorBoundary>}/>
                  <Route path=":id" element={<ErrorBoundary><StudySessionPages.root/></ErrorBoundary>}>
                    <Route index element={<ErrorBoundary><StudySessionPages.overview/></ErrorBoundary>}/>
                    <Route path="settings" element={<ErrorBoundary><StudySessionSettingsPages.root/></ErrorBoundary>}>
                      <Route index element={<ErrorBoundary><StudySessionSettingsPages.general/></ErrorBoundary>}/>
                    </Route>
                  </Route>
                </Route>

                {/* Terrain follow up */}
                <Route path="suivi-terrain" element={<ErrorBoundary><TerrainFollowUpPages.root/></ErrorBoundary>}>
                  <Route index element={<ErrorBoundary><TerrainFollowUpPages.empty/></ErrorBoundary>}/>
                  <Route path=":presetId" element={<Outlet/>}>
                    <Route path="bdi" element={<ErrorBoundary><TerrainFollowUpPages.bdi/></ErrorBoundary>}/>
                    <Route path="meetings" element={<Outlet/>}>
                      <Route index element={<ErrorBoundary><TerrainFollowUpPages.meetings/></ErrorBoundary>}/>
                      <Route path=":slug" element={<ErrorBoundary><TerrainFollowUpPages.meetings/></ErrorBoundary>}/>
                    </Route>
                    <Route path="grouped-notes" element={<ErrorBoundary><TerrainFollowUpPages.groupedNotes/></ErrorBoundary>}/>
                    <Route path="grouped-documents" element={<ErrorBoundary><TerrainFollowUpPages.groupedDocuments/></ErrorBoundary>}/>
                    <Route path="play-time" element={<ErrorBoundary><TerrainFollowUpPages.events/></ErrorBoundary>}/>
                    <Route path="play-time/:eventId" element={<ErrorBoundary><TerrainFollowUpPages.events/></ErrorBoundary>}/>
                    <Route path="stats" element={<ErrorBoundary><TerrainFollowUpPages.stats/></ErrorBoundary>}/>
                    <Route path="aad" element={<ErrorBoundary><TerrainFollowUpPages.aad/></ErrorBoundary>}/>
                  </Route>
                </Route>

                {/* Tutoring follow up */}
                <Route path="tutoring-follow-up" element={<ErrorBoundary><TutoringFollowUpPages.root/></ErrorBoundary>}>
                  <Route index element={<ErrorBoundary><TutoringFollowUpPages.empty/></ErrorBoundary>}/>
                  <Route path=":presetId" element={<Outlet/>}>
                    <Route path="bdt" element={<ErrorBoundary><TutoringFollowUpPages.bdt/></ErrorBoundary>}/>
                    <Route path="notes" element={<ErrorBoundary><TutoringFollowUpPages.notes/></ErrorBoundary>}/>
                    <Route path="stats" element={<ErrorBoundary><TutoringFollowUpPages.stats/></ErrorBoundary>}/>
                  </Route>
                </Route>

                {/* Settings */}
                <Route path="settings" element={<ErrorBoundary><SettingsPages.root/></ErrorBoundary>}>
                  <Route path="profile" element={<ErrorBoundary><SettingsPages.profile.Element/></ErrorBoundary>}/>
                  <Route path="security" element={<ErrorBoundary><SettingsPages.security.Element/></ErrorBoundary>}/>
                  <Route path="mail-types" element={<ErrorBoundary><SettingsPages.mailTypes.Element/></ErrorBoundary>}/>
                  <Route path="presets" element={<ErrorBoundary><SettingsPages.presets.Element/></ErrorBoundary>}/>
                  <Route path="note-codes" element={<ErrorBoundary><SettingsPages.noteCodes.Element/></ErrorBoundary>}/>
                  <Route path="play-time-rules" element={<ErrorBoundary><SettingsPages.playTimeRules.Element/></ErrorBoundary>}/>
                  <Route path="tournament-rules" element={<ErrorBoundary><SettingsPages.tournamentRules.Element/></ErrorBoundary>}/>
                  <Route path="comments" element={<ErrorBoundary><SettingsPages.comments.Element/></ErrorBoundary>}/>
                  <Route path="document-groups" element={<ErrorBoundary><SettingsPages.documentGroups.Element/></ErrorBoundary>}/>
                  <Route path="school-subjects" element={<ErrorBoundary><SettingsPages.schoolSubjects.Element/></ErrorBoundary>}/>
                  <Route path="members" element={<ErrorBoundary><SettingsPages.members.Element/></ErrorBoundary>}/>
                  <Route path="merge-tutors" element={<ErrorBoundary><SettingsPages.mergeTutors.Element/></ErrorBoundary>}/>
                  <Route path="meeting-dates" element={<ErrorBoundary><SettingsPages.meetingDates.Element/></ErrorBoundary>}/>
                </Route>

                {/* Auto pairing */}
                {/* <Route path="auto-pairing" element={<ErrorBoundary><AutoPairingPages.root/></ErrorBoundary>}>
                  
                </Route> */}

                <Route path="automatic-pairing" element={<ErrorBoundary><DraftAutoPairingListPage/></ErrorBoundary>}>
                  <Route index element={<ErrorBoundary><AutoPairingPages.empty/></ErrorBoundary>}/>
                  <Route path="create" element={<ErrorBoundary><AutoPairingPages.create/></ErrorBoundary>}/>
                  <Route path=":draftId" element={<ErrorBoundary><AutoPairingPages.results/></ErrorBoundary>}/>
                  <Route path=":draftId/unmatched-students" element={<ErrorBoundary><AutoPairingPages.unmatchedStudents/></ErrorBoundary>}/>
                  <Route path=":draftId/unmatched-tutors" element={<ErrorBoundary><AutoPairingPages.unmatchedTutors/></ErrorBoundary>}/>
                  <Route path=":draftId/parameters" element={<ErrorBoundary><AutoPairingPages.params/></ErrorBoundary>}/>
                </Route>

                

                {/* End of year */}
                <Route path="end-year-assistant" element={<ErrorBoundary><EndYearAssistantPages.root/></ErrorBoundary>}>
                  <Route index element={<ErrorBoundary><EndYearAssistantPages.welcome/></ErrorBoundary>}/>
                  <Route path="coachs" element={<ErrorBoundary><EndYearAssistantPages.coachs/></ErrorBoundary>}/>
                  <Route path="tutors" element={<ErrorBoundary><EndYearAssistantPages.tutors/></ErrorBoundary>}/>
                  <Route path="students" element={<ErrorBoundary><EndYearAssistantPages.students/></ErrorBoundary>}/>
                  <Route path="pairings" element={<ErrorBoundary><EndYearAssistantPages.pairings/></ErrorBoundary>}/>
                </Route>

                {/* Recruting */}
                <Route path="recruiting" element={<ErrorBoundary><NoteSliderOverContextProvider><RecruitingPages.root/></NoteSliderOverContextProvider></ErrorBoundary>}>
                  <Route index element={<ErrorBoundary><RecruitingPages.myInscriptions/></ErrorBoundary>}/>
                  <Route path="inscriptions" element={<ErrorBoundary><RecruitingPages.inscriptions/></ErrorBoundary>}/>
                  <Route path="forms" element={<ErrorBoundary><RecruitingPages.forms/></ErrorBoundary>}/>
                  <Route path="stats" element={<ErrorBoundary><RecruitingPages.stats/></ErrorBoundary>}/>
                </Route>

                {/* Portal access */}
                <Route path="portal-access" element={<ErrorBoundary><PortalAccessPages.root/></ErrorBoundary>}>
                  <Route index element={<ErrorBoundary><PortalAccessPages.tutors/></ErrorBoundary>}/>
                  <Route path="tutors" element={<ErrorBoundary><PortalAccessPages.tutors/></ErrorBoundary>}/>
                  <Route path="coachs" element={<ErrorBoundary><PortalAccessPages.coachs/></ErrorBoundary>}/>
                  <Route path="teachers" element={<ErrorBoundary><PortalAccessPages.teachers/></ErrorBoundary>}/>
                  <Route path="parents" element={<ErrorBoundary><PortalAccessPages.parents/></ErrorBoundary>}/>
                  <Route path="partners" element={<ErrorBoundary><PortalAccessPages.partners/></ErrorBoundary>}/>
                </Route>

                {/* Import */}
                <Route path="bulk-import" element={<ErrorBoundary><BulkImportPages.root/></ErrorBoundary>}>
                  <Route index element={<ErrorBoundary><BulkImportPages.teachers/></ErrorBoundary>}/>
                  <Route path="students" element={<ErrorBoundary><BulkImportPages.students/></ErrorBoundary>}/>
                  <Route path="teachers" element={<ErrorBoundary><BulkImportPages.teachers/></ErrorBoundary>}/>
                  <Route path="coachs" element={<ErrorBoundary><BulkImportPages.coachs/></ErrorBoundary>}/>
                  <Route path="partners" element={<ErrorBoundary><BulkImportPages.partners/></ErrorBoundary>}/>
                </Route>

                {/* Export */}
                <Route path="export" element={<ErrorBoundary><ExportPage/></ErrorBoundary>}>
                  <Route path=":dataType" element={<ErrorBoundary><ExportContentParams/></ErrorBoundary>}/>
                </Route>
              </Route>

              <Route path="*" element={<Error404/>}/>
            </Routes>
          </BrowserRouter>
      );
}