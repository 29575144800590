import React from 'react';

// Components
import { Container } from 'components/core/container';
import { FlexNav } from 'components/core/layouts/flex';
import { Outlet } from "react-router-dom";
import { SubSideNavLink } from "components/core/nav";
import { FiSettings } from 'react-icons/fi';

// Router
import { useParams, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function NavBar(){
  const { t } = useTranslation();
  const params = useParams();
  return <div >
          <SubSideNavLink path={generatePath(`/pairings/:id/settings/`, params)} Icon={FiSettings} name={t("general-settings")}/>
        </div>
}
function PairingSettings(){
         
  return <Container className="py-6 h-full" >
          <FlexNav>
            <FlexNav.Bar>
              <NavBar/>
            </FlexNav.Bar>
            <FlexNav.Content>
              <Outlet/>
            </FlexNav.Content>
           </FlexNav>
          </Container>
}

import {default as general} from './general';
export const PairingSettingsPages = {root: PairingSettings, general}