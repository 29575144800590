import React from 'react';

// Components
import { FieldsSection } from 'components/core/fields-section';
import { SelectIncrement } from 'components/tools/select_preferences';
import { ModifiableSelectField } from 'components/tools/modifiable_fields';
import { InfoSectionHeading} from 'components/core/typo';
import { CheckBox } from 'components/tools/checkbox';

// Hooks
import { useTranslation } from 'react-i18next';

import { priorityOptions } from 'components/automatic-pairing/create';

export function Parameters({value, updateValue, disabled}){
    const {t} = useTranslation("autoPairing");
    return <>
          <InfoSectionHeading mediumTitle={t("pairings")}/>
          <ModifiableSelectField
            labelSize="xl"
            Field={SelectIncrement}
            disabled={disabled}      
            asBlock={true}
            label={t("pairing-existed-before.label")}
            subLabel={t("pairing-existed-before.description")}
            modify={true}
            formatValue={(d)=>d.name}
            options={priorityOptions}
            defaultOption={priorityOptions[3]}
            value={value.pairingExistedBefore || 1}
            setValue={(value) => { updateValue({ type: 'pairingExistedBefore', value }) }} />
        <ModifiableSelectField
            labelSize="xl"
            Field={SelectIncrement}
            disabled={disabled}
            asBlock={true}
            label={t("common-subject-weight.label")}
            subLabel={t("common-subject-weight.description")}
            modify={true}
            formatValue={(d)=>d.name}
            options={priorityOptions}
            defaultOption={priorityOptions[3]}
            value={value.commonSubjectWeight || 1}
            setValue={(value) => { updateValue({ type: 'commonSubjectWeight', value }) }} />
        <ModifiableSelectField
            labelSize="xl"
            Field={SelectIncrement}
            disabled={disabled}
            asBlock={true}
            label={t("common-school-weight.label")}
            subLabel={t("common-school-weight.description")}
            modify={true}
            formatValue={(d)=>d.name}
            options={priorityOptions}
            defaultOption={priorityOptions[3]}
            value={value.commonSchoolWeight || 1}
            setValue={(value) => { updateValue({ type: 'commonSchoolWeight', value }) }} />
        <ModifiableSelectField
            labelSize="xl"
            noBorder={true}
            Field={CheckBox}
            alwaysFlex={true}
            disabled={disabled}
            label={t("consider-do-not-pair-again.label")}
            subLabel={t("consider-do-not-pair-again.description")}
            modify={true}
            value={value.doNotPairAgain}
            setValue={(value) => { updateValue({ type: 'doNotPairAgain', value }) }} />

            {/* Same checkbox for shouldHaveCommonSubjects */}
        <ModifiableSelectField
            labelSize="xl"
            noBorder={true}
            Field={CheckBox}
            alwaysFlex={true}
            disabled={disabled}
            label={t("tutor-must-have-common-subjects.label")}
            subLabel={t("tutor-must-have-common-subjects.description")}
            modify={true}
            value={value.shouldHaveCommonSubjects}
            setValue={(value) => { updateValue({ type: 'shouldHaveCommonSubjects', value }) }} />
        <ModifiableSelectField
            labelSize="xl"
            noBorder={true}
            Field={CheckBox}
            alwaysFlex={true}
            disabled={disabled}
            label={t("tutor-must-have-common-schools.label")}
            subLabel={t("tutor-must-have-common-schools.description")}
            modify={true}
            value={value.shouldHaveCommonSchools}
            setValue={(value) => { updateValue({ type: 'shouldHaveCommonSchools', value }) }} />


        {/* Should have common format */}
        <ModifiableSelectField
            labelSize="xl"
            noBorder={true}
            Field={CheckBox}
            alwaysFlex={true}
            disabled={disabled}
            label={t("tutor-must-have-common-format.label")}
            subLabel={t("tutor-must-have-common-format.description")}
            modify={true}
            value={value.shouldHaveCommonFormat}
            setValue={(value) => { updateValue({ type: 'shouldHaveCommonFormat', value }) }} />
        {/* Should have common availabilities */}
        <ModifiableSelectField
            labelSize="xl"
            noBorder={true}
            Field={CheckBox}
            alwaysFlex={true}
            disabled={disabled}
            label={t("tutor-must-have-common-availabilities.label")}
            subLabel={t("tutor-must-have-common-availabilities.description")}
            modify={true}
            value={value.shouldHaveCommonSchedule}
            setValue={(value) => { updateValue({ type: 'shouldHaveCommonSchedule', value }) }} />

            {/* STUDENTS */}
          <InfoSectionHeading mediumTitle={t("students")} className="mt-12"/>

          <ModifiableSelectField
            labelSize="xl"
            Field={SelectIncrement}
            disabled={disabled}
            asBlock={true}
            label={t("has-particularity-weight.label")}
            subLabel={t("has-particularity-weight.description")}
            modify={true}
            formatValue={(d)=>d.name}
            options={priorityOptions}
            defaultOption={priorityOptions[3]}
            value={value.hasParticularityWeight || 1}
            setValue={(value) => { updateValue({ type: 'hasParticularityWeight', value }) }} />
        <ModifiableSelectField
            labelSize="xl"
            Field={SelectIncrement}
            disabled={disabled}
            asBlock={true}
            label={t("is-in-accueil-weight.label")}
            subLabel={t("is-in-accueil-weight.description")}
            modify={true}
            formatValue={(d)=>d.name}
            options={priorityOptions}
            defaultOption={priorityOptions[3]}
            value={value.isInAccueilWeight || 1}
            setValue={(value) => { updateValue({ type: 'isInAccueilWeight', value }) }} />
         <ModifiableSelectField
            labelSize="xl"
            Field={SelectIncrement}
            disabled={disabled}
            asBlock={true}
            label={t("student-tutoring-prioritary.label")}
            subLabel={t("student-tutoring-prioritary.description")}
            modify={true}
            formatValue={(d)=>d.name}
            options={priorityOptions}
            defaultOption={priorityOptions[3]}
            value={value.studentIsPriority || 1}
            setValue={(value) => { updateValue({ type: 'studentIsPriority', value }) }} />


<ModifiableSelectField
            labelSize="xl"
            Field={SelectIncrement}
            disabled={disabled}
            asBlock={true}
            label={t("number-of-class-failed-weight.label")}
            subLabel={t("number-of-class-failed-weight.description")}
            modify={true}
            formatValue={(d)=>d.name}
            options={priorityOptions}
            defaultOption={priorityOptions[3]}
            value={value.numberOfClassFailedWeight || 1}
            setValue={(value) => { updateValue({ type: 'numberOfClassFailedWeight', value }) }} />


        <ModifiableSelectField
            labelSize="xl"
            noBorder={true}
            Field={CheckBox}
            alwaysFlex={true}
            disabled={disabled}
            label={t("student-with-contract.label")}
            subLabel={t("student-with-contract.description")}
            modify={true}
            value={value.studentWithContract}
            setValue={(value) => { updateValue({ type: 'studentWithContract', value }) }} />
        

          <InfoSectionHeading mediumTitle={t("tutors")} className="mt-12"/>
          <ModifiableSelectField
            labelSize="xl"
            Field={SelectIncrement}
            disabled={disabled}
            asBlock={true}
            label={t("tutor-years-of-service-weight.label")}
            subLabel={t("tutor-years-of-service-weight.description")}
            modify={true}
            formatValue={(d)=>d.name}
            options={priorityOptions}
            defaultOption={priorityOptions[3]}
            value={value.tutorYearsOfServiceWeight || 1}
            setValue={(value) => { updateValue({ type: 'tutorYearsOfServiceWeight', value }) }} />

        
            <ModifiableSelectField
            labelSize="xl"
            noBorder={true}
            Field={CheckBox}
            alwaysFlex={true}
            disabled={disabled}
            label={t("tutor-must-be-ok-with-particularity.label")}
            subLabel={t("tutor-must-be-ok-with-particularity.description")}
            modify={true}
            value={value.tutorMustBeOkWithParticularity}
            setValue={(value) => { updateValue({ type: 'updateValue', value }) }} />
        <ModifiableSelectField
            labelSize="xl"
            noBorder={true}
            Field={CheckBox}
            alwaysFlex={true}
            disabled={disabled}
            label={t("tutor-must-be-ok-with-accueil.label")}
            subLabel={t("tutor-must-be-ok-with-accueil.description")}
            modify={true}
            value={value.tutorMustBeOkWithAccueil}
            setValue={(value) => { updateValue({ type: 'tutorMustBeOkWithAccueil', value }) }} />
        
        </>
}

export function ReadOnlyParameters({...props}){
    const {t} = useTranslation("autoPairing");
    return <FieldsSection 
                title={t("new-settings.params.title")}
                name="main1"
                >
                <Parameters {...props}/>
            </FieldsSection>
}